import authReducer from './authReducer';
import noteReducer from './notesReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'
import notesReducer from './notesReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    firebase: firebaseReducer,
    notes: notesReducer
});

 export default rootReducer