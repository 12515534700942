import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyB0ZGV0VpxIJmnOaqRXJ9NFHb0bwMvOHH8",
    authDomain: "darpan-darpan.firebaseapp.com",
    databaseURL: "https://darpan-darpan.firebaseio.com",
    projectId: "darpan-darpan",
    storageBucket: "darpan-darpan.appspot.com",
    messagingSenderId: "575216915841",
    appId: "1:575216915841:web:7e4c38cd5dd58c0b394480",
    measurementId: "G-E0C43C0WCS"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true});

export default firebase