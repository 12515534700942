import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './bookingsDash.css';
import Nav from './nav/nav';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import axios from 'axios';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import M from "materialize-css";
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getIdToken } from '../store/actions/authActions'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';
import { Checkbox, FormControl } from '@material-ui/core';
import { TextField } from 'material-ui';
import {env} from "../config/env";
import { ComponentToPrint } from './printComponent';
import Loader from "react-loader-spinner";


const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 200px 10px 200px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);


class BookingsDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookings: null,
            searchTerm: "",
            userSearchTerm: "",
            users: null,
            bookingForId: null,
            selectedDate: new Date(),
            bookingReason: "",
            bookingPaymentMethod: "None",
            bookingIsAnnual: false,
            bookingId: null,
            edit: false,
            hukamnamaAng: null,
            hukamnamaSabad: null,
            hTitle: null,
            bhogDate: null,
            readyToPrint: false,
            hukamnama: null,
            ht: null,
            loader: false,
            hClass: null
        }

        this.searchUserRef = React.createRef();
    }

    

    componentDidMount () {
 
        
        this.getAllBookingsWithMembers();



        const options = {
            onOpenStart: () => {
              ////console.log("Open Start");
              
            },
            onOpenEnd: () => {
              ////console.log("Open End");
             
            },
            onCloseStart: () => {
              ////console.log("Close Start");

            },
            onCloseEnd: () => {
              ////console.log("Close End");
             
            },
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: false,
            startingTop: "4%",
            endingTop: "10%"
          };

          M.Modal.init(this.Modal, options);

          M.Modal.init(this.addBookingModal, options);
          
          if(this.props.location.search.substring(1)){
            M.Modal.getInstance(this.Modal).open();
            const search = this.props.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            const name = params.get('add'); // bar
          
            this.setState({userSearchTerm: name})
            
        }
        
    }
    async getAllBookingsWithMembers() {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

            //console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        axios.get(`${env.backendApiServer}/getBookingsWithMembers`, {
       // axios.get(`${env.backendApiServer}/getMemberByx/kidderminster`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            ////console.log(res);
            this.setState({bookings: res.data});
        })

        
    }

    async getAllBookings() {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

            //console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        axios.get(`${env.backendApiServer}/getBookings`, {
       // axios.get(`${env.backendApiServer}/getMemberByx/kidderminster`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            //console.log(res);
            this.setState({bookings: res.data});
        })

        
    }

    async getAllMembersByx() {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
       axios.get(`${env.backendApiServer}/getMemberByx/${this.state.userSearchTerm}`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            //console.log(res);
            this.setState({users: res.data});
        })
    }
    async addNewBooking() {
        const uid = this.props.auth.uid;
            const firebase = getFirebase();
            var idToken = null;
            try {
                idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
                ////console.log(idToken);
            } catch(e){
                //console.log(`Error ${e}`);
            }
            const formattedDate = new Date(this.state.selectedDate).getFullYear()+'-'+(new Date(this.state.selectedDate).getMonth()+1)+'-'+new Date(this.state.selectedDate).getDate()
            var annual = 0
            if (this.state.bookingIsAnnual) {
                annual = 1;
            }
            ////console.log(this.state.bookingForId);
            const payload = {
                "sangatid": this.state.bookingForId,
                "payment": this.state.bookingPaymentMethod,
                "reason": this.state.bookingReason,
                "bhogdate": formattedDate,
                "annual":  this.state.bookingIsAnnual ? 1 : 0
            }
           ////console.log(payload)
    
           axios.post(`${env.backendApiServer}/addBooking?idToken=${idToken}&uid=${uid}`, payload).then(res => {
                //console.log(res);
               
            })
    }

    async updateBooking() {
        const uid = this.props.auth.uid;
            const firebase = getFirebase();
            var idToken = null;
            try {
                idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
                ////console.log(idToken);
            } catch(e){
                //console.log(`Error ${e}`);
            }
            const formattedDate = new Date(this.state.selectedDate).getFullYear()+'-'+(new Date(this.state.selectedDate).getMonth()+1)+'-'+new Date(this.state.selectedDate).getDate()
            var annual = 0
            if (this.state.bookingIsAnnual) {
                annual = 1;
            }
            const payload = {
                "sangatid": this.state.bookingForId,
                "payment": this.state.bookingPaymentMethod,
                "reason": this.state.bookingReason,
                "bhogdate": formattedDate,
                "annual":  this.state.bookingIsAnnual ? 1 : 0,
                "hukamnamaang": this.state.hukamnamaAng,
                "hukamnamasabad": this.state.hukamnamaSabad
            }
           ////console.log(payload)
    
           axios.patch(`${env.backendApiServer}/updateBooking/${this.state.bookingId}?idToken=${idToken}&uid=${uid}`, payload).then(res => {
                //console.log(res);
               
            })
    }
    async getBookingsById() {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
       await axios.get(`${env.backendApiServer}/getBooking/${this.state.bookingId}`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            this.setState({
                bookingForId: res.data[0].sangat_id,
                selectedDate: res.data[0].bhogdate,
                bookingReason: res.data[0].reason,
                bookingPaymentMethod: res.data[0].payment,  
                bookingIsAnnual: res.data[0].annual ? true : false,   
                bookingId: res.data[0].path_id,
                hukamnamaAng: res.data[0].hukamnama_ang,
                hukamnamaSabad: res.data[0].hukamnam_sabad      
            });
            //console.log(res);
        })
    }



    onChangeBookingAnnual=() => {
        //console.log(this.state.bookingIsAnnual);
        if(this.state.bookingIsAnnual){
            this.setState({bookingIsAnnual: false});
        }else{
            this.setState({bookingIsAnnual: true});
        }
        
    }
    handleChange = (event) => {
        this.setState({searchTerm: event.target.value});
        ////console.log(this.state.searchTerm.length);
    }

    handleBookingFormChangeEvents = (event) => {
        this.setState({[event.currentTarget.id]: event.target.value})
        
    }

    handleUserChange = (event) => {
        this.setState({userSearchTerm: event.target.value});
       
        if(this.state.userSearchTerm.length >= 3) {
            this.getAllMembersByx()
        } else {
            this.setState({users: null});
        };
    }

    editBooking = async (event) => {
        ////console.log(event.currentTarget.id);
        this.setState({bookingId: event.currentTarget.id, edit: true});
        this.getBookingsById();

    }
    loadBooking = async (event) => {
        ////console.log(event)
        await this.setState({bookingId: event});
        await this.getBookingsById();
        
    }

    getPrintContent = async (event) => {
        this.setState({
            'loader':true
        })
        return new Promise( (resolve, reject) => {
             this.setState({bookingId: event});
             this.getBookingsById().then(res => {
                var lines = ""
                //async componentDidUpdate() {
                //console.log("this is the ang: "+this.state.ang)

                if(this.state.hukamnamaAng && (this.state.hukamnama==null)) {
                    this.findShabad().then(res => { 
                    for(var i=0; i<res.shabad.length; i++){
                        if(i==0){ 
                        this.setState({'ht':res.shabad[i].line.gurmukhi.unicode })
                        }else{
                        lines = lines + res.shabad[i].line.gurmukhi.unicode;
                        }
                    }
                    var bd = new Date(this.state.selectedDate);
                    var dt = bd.getDate();
                    var month = bd.getMonth();
                    if (dt < 10) {
                        dt = '0' + dt;
                      }
                    if (month < 10) {
                    month = '0' + (parseInt(month)+1);
                    }
                    this.setState({
                        'hukamnama': lines,
                        'hTitle': 'ਹਰਿ ਮਨਿ ਤਨਿ ਵਸਿਆ ਸੋਈ ॥ ਜੈ ਜੈ ਕਾਰੁ ਕਰੇ ਸਭੁ ਕੋਈ ॥ ਗੁਰ ਪੂਰੇ ਕੀ ਵਡਿਆਈ ॥ ਤਾ ਕੀ ਕੀਮਤਿ ਕਹੀ ਨ ਜਾਈ ॥੧॥',
                        'bhogDate':   dt + '/'+ month + '/' +bd.getFullYear()
                    })
                    this.setState({
                        'loader': false
                    })
                    //console.log(this.state.hukamnama.length)
                      // if(this.state.hukamnama.length>1517) {
                      //   this.setState({
                      //     'hClass': 'mainBodySmall'
                      //   })
                      // } else if (this.state.hukamnama.length<500){
                      //   this.setState({
                      //     'hClass': 'mainBodyLarge'
                      //   });
                      // } else 
                      {
                        this.setState({
                          'hClass': 'mainBody'
                        })
                      }
                    resolve("Hukamnama has been generated");
                });
                }
            });
        });


    }

    clearAllAfterPrint = async (event) => {
        this.setState({
            ht: null,
            hukamnama: null,
            hTitle: null,
            bhogDate: null,
            hukamnamaAng: null
        })
    }


    addBooking = async (event) => {
        if (this.state.edit){
            this.updateBooking();
        } else {
            
            await this.addNewBooking();
            this.resetAllFormValues();
            
        }
        if (this.state.edit) this.setState({edit: false})
    }
    deleteBooking = async (event) => {
        const id = event.currentTarget.id;
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
       axios.delete(`${env.backendApiServer}/deleteBooking/${id}`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            //console.log(res);
            //this.setState({users: res.data});
            this.getAllBookingsWithMembers();
        })

    }
    
    resetAllFormValues = () => {
        this.setState({
            addBookingPaymentMethod: "",
            bookingForId: "",
            bookingPaymentMethod: "",
            bookingReason: "",
            selectedDate: "",
            bookingIsAnnual: null,
            hukamnamaAng: "",
            hukamnamaSabad: "",
            bookingId: "",
            bhogDate: ""
        })
    }
    handleTRClick = (event) => {
        this.resetAllFormValues();
        this.setState({bookingForId: event.target.id});
    }
    datePickerChangeHandler = (date) => {
        
        this.setState({selectedDate: date});
    }
    handleAddBookingReason = (reason) => {
        //console.log(reason);
        this.setState({addBookingReason: reason});
    }
    redirectToAddNew = () => {
        
          return window.location.href='/membermgmt?add'
        
      }
    reactToPrintTrigger = (event) => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.
    
        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
    
        // Good
        ////console.log(event)
        //return <button>Print</button>;
        return <i class="small material-icons userActionButton">print</i>

      }
      async findShabad(){
        var shabadId = ""
        var nextShabadId = ""
        var prevShabadId = ""
        var ang = null
        var shabadCount = 0;
        //console.log("Ang: "+ this.state.hukamnamaAng)
        if(this.state.hukamnamaAng>1) {
            var prevAng = this.state.hukamnamaAng-1;
        } else {
            var prevAng = this.state.hukamnamaAng;
        }
        await this.getBani(prevAng).then(res => {
          //console.log(res.data);
    
          shabadId = res.data.page[res.data.page.length-1].line.shabadid;
        
        });
        await this.getSabad(shabadId).then(res => {
          nextShabadId = res.data.shabadinfo.navigation.next.id
          prevShabadId = res.data.shabadinfo.navigation.previous.id
          ang = res.data.shabadinfo.pageno
          shabadId = nextShabadId
          
        })
    
        while(ang <= this.state.hukamnamaAng) {
          var result = null
          await this.getSabad(shabadId).then( res => {
            result = res;
          });
          var nextShabadId = result.data.shabadinfo.navigation.next.id
          var  prevShabadId = result.data.shabadinfo.navigation.previous.id
          var ang = result.data.shabadinfo.pageno
          var shabadId = nextShabadId
          shabadCount++;
          if(shabadCount == this.state.hukamnamaSabad) return result.data
    
          //console.log('shabad: ' + shabadId + ' ang: '+ ang + ' count: ' + shabadCount)
          //console.log(result.data.shabad[1].line.gurmukhi.unicode)
            
            
          }
        
        
    
    
      }
      async  getBani(ang) {
        return axios.get('https://api.gurbaninow.com/v2/ang/'+ang, {
          
        })
    
      }
      async getSabad(id){
        return axios.get('https://api.gurbaninow.com/v2/shabad/'+id);
      }
    render() {
        var filteredBookings = []
        const searchTerm = this.state.searchTerm;
        if(this.state.bookings){

            this.state.bookings.filter(function(item) {
                    if(item.reason.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.reason.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.bhogdate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.payment.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.phone.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        filteredBookings.push(item);
                    }
                
            });

        }


        if (!this.props.auth.uid) return <Redirect to='/login' />
        return (
            <div>
                 <Nav />
                 
                 <NavLink to='/'><i class="small material-icons backArrow">keyboard_return</i></NavLink>

                <div class="modalButtonMargin">
                        <a className="modal-trigger waves-effect waves-light btn" data-target="modal1"  onClick={(e)=> {this.searchUserRef.current.focus()}}>
                            Add Booking
                        </a>
                </div>
             


                <h3 className="headingText">Path Bookings</h3>
                <div class="row">
                    <div class="input-field col s6">
                        <input id="searchTerm" type="text" class="validate" onChange={this.handleChange} />
                        <label class="active" for="searchTerm">Search Members</label>
                    </div>
                </div>
                
                <Loader
                    className='loader'
                    type="TailSpin"
                    color="#ff9800"
                    height={100}
                    width={100}
                    visible={this.state.loader}
                    //3 secs
                />
                <TableContainer component={Paper}>
                    <Table className="table" aria-label="simple table">
                            
                                
                            
                        <TableHead>
                        
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">City</TableCell>
                            <TableCell align="right">Occasion</TableCell>
                            <TableCell align="right">Bhog Date</TableCell>
                            <TableCell align="right">Actions</TableCell>
                            {/* <TableCell align="right">Hukamnama Ang</TableCell>
                            <TableCell align="right">Hukamnama Sabad</TableCell> */}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredBookings ? filteredBookings.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell component="th" scope="row">
                             
                                {row.firstname+" "+row.surname}
                            </TableCell>
                            <TableCell align="right">{row.city}</TableCell>
                            <TableCell align="right">{row.reason}</TableCell>
                            <TableCell align="right">{new Date(row.bhogdate).toDateString()}</TableCell>
                            <TableCell align="right">
                                <i class="small material-icons userActionButton modal-trigger" id={row.path_id} data-target="addBookingModal" onClick={this.editBooking}>edit</i>
                                <i class="small material-icons userActionButton modal-trigger redHover" data-target="modal2" id={row.path_id} onClick={this.deleteBooking}>delete_forever</i>
                                
                                   <ReactToPrint id={row.path_id}
                                        trigger={this.reactToPrintTrigger}
                                        content={() => this.componentRef}
                        
                                        onBeforeGetContent={() => this.getPrintContent(row.path_id)}
                                        onAfterPrint={() => this.clearAllAfterPrint()}
                                    /> 
                                
                            </TableCell>
                            {/* <TableCell align="right">{row.hukamnamaAng}</TableCell>
                            <TableCell align="right">{row.hukamnamaSabad}</TableCell> */}
                            </TableRow>
                        )): <h1><center>Loading Data...</center></h1>}
                        </TableBody>
                    </Table>
                </TableContainer>
                
                    <div className='hidden'>
                        <ComponentToPrint  hClass={this.state.hClass} title={this.state.hTitle} ang={this.state.hukamnamaAng} hukamnama={this.state.hukamnama} ht={this.state.ht} bhogDate={this.state.bhogDate} ref={el => (this.componentRef = el || '')} />
                    </div>
               
                {/* Start of Modal */}
                <div
                ref={Modal => {
                    this.Modal = Modal;
                }}
                id="modal1"
                className="modal modal-fixed-footer addBookingModal"
                >
                {/* If you want Bottom Sheet Modal then add 
                                bottom-sheet class to the "modal" div
                                If you want Fixed Footer Modal then add
                                modal-fixed-footer to the "modal" div*/}
                <div className="modal-content searchUserModalContent">
                    <h4>Search User</h4>
                    <div class="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" onClick={this.redirectToAddNew}>
                        Add Member
                        </a>
                    </div>
                            { 1 ?  
                                <div>
                                    <div class="row">
                                        <div class="input-field col s6">
                                            <input ref={this.searchUserRef} id="userSearchTerm" type="text" class="validate" value={this.state.userSearchTerm} onChange={this.handleUserChange} />
                                            <label class="active" for="userSearchTerm">Search Members</label>
                                        </div>
                                    </div>
                                    <TableContainer component={Paper}>
                                        <Table className="searchUserTable" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell >Name</TableCell>
                                                <TableCell align="right">Address</TableCell>
                                                <TableCell align="right">Email</TableCell>
                                               
                                                {/* <TableCell align="right">Hukamnama Ang</TableCell>
                                                <TableCell align="right">Hukamnama Sabad</TableCell> */}
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {this.state.users ? this.state.users.map((row, index) => (
                                                
                                                <TableRow className="addBookingTR modal-trigger" id={row.ID} key={index}  onClick={this.handleTRClick} data-target="addBookingModal">
                                                <TableCell  className="modal-trigger" data-target="addBookingModal" id={row.ID} component="th" scope="row">
                                                    {row.firstname+" "+row.surname}
                                                </TableCell>
                                                <TableCell  className="modal-trigger" data-target="addBookingModal" id={row.ID} align="right">{row.address1 ? row.address1+' ,': ""} {row.address2 ? row.address2+' ,': ""} {row.city ? row.city +' ,': ""} {row.state?row.state+' ,': ""} {row.country ? row.country: ""}</TableCell>
                                                <TableCell  className="modal-trigger" data-target="addBookingModal" id={row.ID} align="right">{row.email}</TableCell>
                                                
                                                {/* <TableCell align="right">{row.hukamnamaAng}</TableCell>
                                                <TableCell align="right">{row.hukamnamaSabad}</TableCell> */}
                                                </TableRow>
                                            )): <h1><center></center></h1>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            : <div>Loading...</div>}    
                    </div>
                <div className="modal-footer">
                    <div class="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" >
                        Cancel
                        
                        </a>
                    </div>
                    
                </div>
                </div>


                {/* Start of Modal */}
                <div
                ref={addBookingModal => {
                    this.addBookingModal = addBookingModal;
                }}
                id="addBookingModal"
                className="modal modal-fixed-footer addBookingModal"
                >
                {/* If you want Bottom Sheet Modal then add 
                                bottom-sheet class to the "modal" div
                                If you want Fixed Footer Modal then add
                                modal-fixed-footer to the "modal" div*/}
                <div className="modal-content">
                    <h4>Add New Booking</h4>
                            { 1 ?  
                                <div>
                                   <form class="col s12">
                                        <div class="input-field col s6 ">
                                            <i class="material-icons prefix">featured_play_list</i>
                                            <input disabled  value={this.state.bookingId} id="id" type="text" class="validate"/>
                                            
                                        </div>
                                      
                                        <div class="input-field col s6">
                                            <i class="material-icons prefix">person</i>
                                           
                                            
                                            <textarea className='bookingReason materialize-textarea' id="bookingReason" onChange={this.handleBookingFormChangeEvents}  value={this.state.bookingReason} id="bookingReason" data-length="200"/>
                                            <label for="bookingReason">Path Reason</label>
                                            
                                        </div>
                                
                                        <div class="input-field col s6">
                                            <i class="material-icons prefix">date_range</i>
                                           
                                    
                                            <div className="addBookingDateSelector">
                                            <FormControl >
                                                <MuiPickersUtilsProvider id="bhogDate" utils={DateFnsUtils}>
                                                    <KeyboardDatePicker 
                                                        disableToolbar
                                                        variant="inline"
                                                        format="yyyy-MM-dd"
                                                        margin="normal"
                                                        id="bhogDate"
                                                        
                                                        value={this.state.selectedDate}
                                                        onChange={this.datePickerChangeHandler}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />


                                                </MuiPickersUtilsProvider>
                                            </FormControl>
                                            </div>
                                        </div>

                                        <div class="input-field col s6">
                                            <i class="material-icons prefix">payment</i>
                                            <FormControl >
                                                <Select
                                                labelId="paymentLabel"
                                                id="paymentMethod"
                                                
                                                value={this.state.bookingPaymentMethod}
                                                onChange={(e) => {
                                                        this.setState({bookingPaymentMethod: e.target.value});
                                                }}
                                                input={<BootstrapInput />}
                                                className="addBookingReasonSelect">
                                                <MenuItem value="None">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Cash">Cash</MenuItem>
                                                <MenuItem value="Card">Card</MenuItem>
                                                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div class="input-field col s6">
                                            <i class="material-icons prefix">person</i>
                                           
                                            
                                            <textarea className='bookingReason materialize-textarea' id="hukamnamaAng" onChange={this.handleBookingFormChangeEvents}  value={this.state.hukamnamaAng}  data-length="200"/>
                                            <label for="bookingReason">Hukamnama Ang</label>
                                            
                                        </div>

                                        <div class="input-field col s6">
                                            <i class="material-icons prefix">person</i>
                                           
                                            
                                            <textarea className='bookingReason materialize-textarea' id="hukamnamaSabad" onChange={this.handleBookingFormChangeEvents}  value={this.state.hukamnamaSabad}  data-length="200"/>
                                            <label for="bookingReason">Hukamnama Sabad</label>
                                            
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">refresh</i>
                                            <div className="newBookingCheckboxLabel">
                                                <InputLabel id="annualBooking">Annual Booking</InputLabel>
                                            </div>
                                            <div className='newBookingCheckbox'>
                                                <Checkbox  color="orange" onClick={() => this.setState({bookingIsAnnual: !this.state.bookingIsAnnual})} checked={this.state.bookingIsAnnual}/>
                                            </div>
                                            
                                        </div>

                                </form>  
                                </div>
                            : <div>Loading...</div>}    
                    </div>
                <div className="modal-footer">
                    <div class="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" onClick={this.addBooking}>
                        OK
                        </a>
                    </div>
                    <div class="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" >
                        Cancel
                        
                        </a>
                    </div>
                    
                </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return { 
        auth: state.firebase.auth,
        userToken: state.auth.action
    }
  }
export default connect(mapStateToProps)(BookingsDash)