import React from 'react';
import { Link } from 'react-router-dom';
import SignedinLinks from './signedinLinks';
import SignedoutLinks  from './signedoutLinks';
import { connect } from 'react-redux';
import './nav.css';

const Nav = (props) => {
    //console.log(props.auth)
    return (
       <nav className="nav-wrapper grey darken-3">
           <div className="container">
                <Link to='/' className="brand-logo blogo">
                    <div className='oText'>Darbar</div> Management
                </Link>
                { props.auth.uid ?
                    <SignedinLinks />:
                    <SignedoutLinks />
                }
                
           </div>
       </nav>
    )
}


  const mapStateToProps = (state) => {
      return { 
          auth: state.firebase.auth
      }
    }

export default connect(mapStateToProps, null)(Nav)