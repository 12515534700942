import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Nav from './components/nav/nav';
import Dash from './components/dash';
import Login from './components/login';
import BookingsDash from './components/bookingsDash';
import MembershipMgmt from './components/membershipMgmt';
import Hukamnama from './components/hukamnama'
function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Dash} />
          <Route exact path='/dash' component={Dash} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/bookings' component={BookingsDash} />
          <Route exact path='/membermgmt' component={MembershipMgmt} />
          <Route exact path='/hukamnama' component={Hukamnama} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
