
import React, { Component, forwardRef, useRef, useImperativeHandle } from 'react'
import {Form, Button, Container, Row, Col} from 'react-bootstrap'
import './login.css'
import { FirebaseAppProvider, useFirebaseApp, useAuth, AuthCheck } from 'reactfire';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { signIn } from '../store/actions/authActions'
import { signOut } from '../store/actions/authActions'
import Nav from './nav/nav';
import { Redirect } from 'react-router-dom';
import bgimage from './login-bg.jpg';
  
  
  

class Login extends Component {
    state = {
        email: '',
        password: ''
    }
    
      
      handleSubmit = async (e) => {
         //const [auth] = useAuth();
            //await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
         // await this.auth.signInWithEmailAndPassword(this.state.uname,this.state.pword);
         // //console.log();
            //await auth.signOut();
            e.preventDefault();
            ////console.log(this.state);
            ////console.log('clicked');
            this.props.signIn(this.state);
            

      }
  
  
      handleChange = (e) => {
          ////console.log(e.target);
          this.setState({
            [e.target.id]: e.target.value
          });
      }
  
     
  
    
      render() {
          
        let error = this.props.loginError;
        if (this.props.auth.uid) return <Redirect to='/dash' />
          return (
            <div>
                <Nav />

                
                <div className='cover' />

    
                    
                <div className="container">
                    <div className='row'>
                      <div className='col s10 m10 l8 xl6 offset-s1 offset-m1 offset-l2 offset-xl3'>
                          <div className='loginForm'>
                            <form className="white" onSubmit={this.handleSubmit}>
                            <h5 className="grey-text text-darken-3 center">Sign In</h5>
                            
                            <div class="section">
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id='email' onChange={this.handleChange} />
                                </div>
                                <div className="input-field">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id='password' onChange={this.handleChange} />
                                </div>
                                <div className='errorMsg'>
                                  {error}
                                </div>
                                <div className="center input-field">
                                    <button className="btn amber z-depth-1">Login</button>
                                </div>

                               
                            </div>
                            </form>

                          </div>
                        </div>
                    </div>  
                </div>
            </div>        
        
          )
      }
  }

const mapDispatchToProps = (dispatch) => {
  ////console.log(dispatch);
  return { 
    signIn: (creds) => dispatch(signIn(creds)),
    signOut: () => dispatch(signOut())
  }
}
const mapStateToProps = (state) => {
    return { 
        auth: state.firebase.auth,
        loginError: state.auth.authError
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Login)