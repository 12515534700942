import React from 'react';
import './printComponent.css';
import axios from 'axios';

// Using a class component, everything works without issue
export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    //
    this.state = {
   
    }
    
  }
  async  getSnapshotBeforeUpdate(prevProps, prevState) {
    
  }

  componentWillUnmount() {
   
  }

  // async prepareLines (shabad){
  //   await this.findShabad().then(res => {
  //     var lines = this.prepareLines(res);
  //     //console.log(res);
  //     this.setState({'hukamnama': lines});
  //   });
  //   var gurmukhi = ""
  //   this.setState({'title': shabad.shabad[0].line.gurmukhi.unicode});
  //   for( var line=1; line < shabad.shabad.length; line++){
  //     gurmukhi = gurmukhi + "\n"+ shabad.shabad[line].line.gurmukhi.unicode
  //   }
  //   return gurmukhi
  // }
  




  render() {
    return (
     
      <div>
        <div className='gurmukhi mainTitle'>{this.props.title}</div>
        <div className='date'>{this.props.bhogDate}</div>
        <div className='ang'>{this.props.ang}</div>
        <div className={"gurmukhi "+this.props.hClass}>{this.props.ht}<br/>{this.props.hukamnama}</div>
      </div>
    );
  }
}