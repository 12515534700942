import React from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import { ComponentToPrint } from './printComponent';
import axios from 'axios';
import Nav from './nav/nav';
import { NavLink, Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "./hukamnama.css";
import "../fonts/GURAKH_H.ttf";
import Select from 'react-select';

class Hukamnama extends React.PureComponent {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    //
    this.state = {
        // printContent: null
        bookings: null,
        searchTerm: "",
        userSearchTerm: "",
        users: null,
        bookingForId: null,
        selectedDate: new Date(),
        bookingReason: "",
        bookingPaymentMethod: "None",
        bookingIsAnnual: false,
        bookingId: null,
        edit: false,
        hukamnamaAng: null,
        hukamnamaSabad: null,
        hTitle: null,
        bhogDate: null,
        readyToPrint: false,
        hukamnama: null,
        ht: null,
        title: null,
        loader: false,
        hClass: 'mainBody',
        options: [],
        selectedOption: null
    }
    
  }
  clearAllAfterPrint = async (event) => {
    this.setState({
        ht: null,
        hukamnama: null,
        hTitle: null,
        bhogDate: null,
        hukamnamaAng: null,
        ang: null,
        title: null,
        options: [],
        selectedOption: null
    })

}

handleSelectChange = (selection) => {
  this.setState({ selectedOption:  selection});
  console.log(`Option selected:`, selection);
};

loadShabads = async (events) => {

  this.setState({
    'loader':true
  })
  await this.getAllSabads();
  console.log(this.state.options);
  this.setState({
    'loader':false
  })
}

  getPrintContent = async (event) => {
    
    var selectionShabad = this.state.selectedOption.value;
    return new Promise( (resolve, reject) => {
        this.setState({bookingId: event});

        var lines = ""
            //async componentDidUpdate() {
            //console.log("this is the ang: "+this.state.ang)

            if((this.state.hukamnama==null)) {
                this.getSabad(selectionShabad).then(result => {
                var res = result.data 
               

                  if(res) {
                      for(var i=0; i<res.shabad.length; i++){
                          if(i==0){ 
                          this.setState({'ht':res.shabad[i].line.gurmukhi.unicode })
                          }else{
                          lines = lines + res.shabad[i].line.gurmukhi.unicode;
                          }
                          
                      }
                      var bd = new Date(this.state.selectedDate);
                      var dt = bd.getDate();
                      var month = bd.getMonth();
                      if (dt < 10) {
                          dt = '0' + dt;
                        }
                      if (month < 10) {
                      month = '0' + (parseInt(month)+1);
                      }
                      this.setState({
                          'hukamnama': lines,
                          'hTitle': this.state.title,
                          'bhogDate':   dt + '/'+ month + '/' +bd.getFullYear()
                      }) 
                      {
                        this.setState({
                          'hClass': 'mainBody'
                        })
                      }
                      this.setState({
                        'loader':false
                      })
                      resolve("Hukamnama has been generated");
                      
                    }
                  });
            }
    });


  }
  // async findShabad(){
  //   var shabadId = ""
  //   var nextShabadId = ""
  //   var prevShabadId = ""
  //   var ang = null
  //   var shabadCount = 0;
  //   //console.log("Ang: "+ this.state.ang)
  //   if(this.state.ang>1) {
  //       var prevAng = this.state.ang-1;
  //   } else {
  //       var prevAng = this.state.ang;
  //   }
  //   await this.getBani(prevAng).then(res => {
  //     //console.log(res.data);

  //     shabadId = res.data.page[res.data.page.length-1].line.shabadid;
    
  //   });
  //   await this.getSabad(shabadId).then(res => {
  //     nextShabadId = res.data.shabadinfo.navigation.next.id
  //     prevShabadId = res.data.shabadinfo.navigation.previous.id
  //     ang = res.data.shabadinfo.pageno
  //     shabadId = nextShabadId
      
  //   })

  //   while(ang <= this.state.ang) {
  //     var result = null
  //     await this.getSabad(shabadId).then( res => {
  //       result = res;
  //     });
  //     var nextShabadId = result.data.shabadinfo.navigation.next.id
  //     var  prevShabadId = result.data.shabadinfo.navigation.previous.id
  //     var ang = result.data.shabadinfo.pageno
  //     var shabadId = nextShabadId
  //     shabadCount++;
  //     if(shabadCount == this.state.hukamnamaSabad) {
  //       //console.log(result.data);
  //       return result.data
  //     }

  //     //console.log('shabad: ' + shabadId + ' ang: '+ ang + ' count: ' + shabadCount)
  //     //console.log(result.data.shabad[1].line.gurmukhi.unicode) 
  //     }
  // }



  async getAllSabads(){
    var shabadId = ""
    var nextShabadId = ""
    var prevShabadId = ""
    var ang = null
    var shabadCount = 0;
    var selectionOptions = [];
    //console.log("Ang: "+ this.state.ang)
    if(this.state.ang>1428) {
      return;
    }
    if(this.state.ang>1) {
        var prevAng = this.state.ang-1;
    } else {
        var prevAng = this.state.ang;
    }
    await this.getBani(prevAng).then(res => {
      //console.log(res.data);

      shabadId = res.data.page[res.data.page.length-1].line.shabadid;
    
    });

    await this.getSabad(shabadId).then(res => {
      nextShabadId = res.data.shabadinfo.navigation.next.id
      prevShabadId = res.data.shabadinfo.navigation.previous.id
      ang = res.data.shabadinfo.pageno
      shabadId = nextShabadId
      
    })

    while(ang <= this.state.ang) {
      var result = null
      await this.getSabad(shabadId).then( res => {
        result = res;
      });
      var nextShabadId = result.data.shabadinfo.navigation.next.id
      var  prevShabadId = result.data.shabadinfo.navigation.previous.id
      var ang = result.data.shabadinfo.pageno
      var shabadId = nextShabadId
      
      //console.log(result.data);

      if(result.data.shabad[1].line.gurmukhi.unicode == "ੴ ਸਤਿਗੁਰ ਪ੍ਰਸਾਦਿ ॥"){
        selectionOptions.push({"label": result.data.shabad[2].line.gurmukhi.unicode, "value": result.data.shabadinfo.shabadid});
      } else {
        selectionOptions.push({"label": result.data.shabad[1].line.gurmukhi.unicode, "value": result.data.shabadinfo.shabadid});
      }
      // shabadCount++;
      // if(shabadCount == this.state.hukamnamaSabad) {
      //   //console.log(result.data);
      //   return result.data
      // }

      //console.log('shabad: ' + shabadId + ' ang: '+ ang + ' count: ' + shabadCount)
      //console.log(result.data.shabad[1].line.gurmukhi.unicode) 
      }

      this.setState({options: selectionOptions});

  }
  async  getBani(ang) {
    return axios.get('https://api.gurbaninow.com/v2/ang/'+ang, {
      
    })

  }
  async getSabad(id){
    return axios.get('https://api.gurbaninow.com/v2/shabad/'+id);
  }
  handleChange = (event) => {
    this.setState({[event.currentTarget.id]: event.target.value});    
  }

 
  reactToPrintTrigger = (event) => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    ////console.log(event)
    //return <button>Print</button>;
    //return <i class="small material-icons userActionButton">print</i>
    return <a className="waves-effect waves-light btn"  onClick={(e)=> {this.getPrintContent()}}>
                Print Hukamnama
            </a>
  }

  render() {
    if (!this.props.auth.uid) return <Redirect to='/login' />
    return (
      
      <div>
         <Nav />
         <NavLink to='/'><i className="small material-icons backArrow">keyboard_return</i></NavLink>
        <div className="row">
            <div className="input-field col s6">
                <input className='gurmukhi validate' placeholder="" id="title" type="text" onChange={this.handleChange} />
                <label className="active" htmlFor="title">Title</label>
            </div>
        </div>
        <div className="row">
            <div className="input-field col s6">
                <input id="ang" type="number" min="1" max="1430" className="validate" onChange={this.handleChange} />
                <label className="active" htmlFor="ang">Ang</label>
            </div>
            <div className="input-field col s6">
                {/* <input id="hukamnamaSabad" type="number" min="1" max="5" className="validate" onChange={this.handleChange} />
                <label className="active" htmlFor="hukamnamaSabad">Sabad</label> */}
                <a className="waves-effect waves-light btn"  onClick={(e)=> {this.loadShabads()}}>
                 Load
                </a>

            </div>
        </div>
        <div className="row">
        
        </div>
        <div className="row">
          <div className="col s6">
            <Select
              value={this.state.selectedOption}
              onChange={this.handleSelectChange}
              options={this.state.options}
            />
            </div>
            <div className="col s6">
          <ReactToPrint
              trigger={this.reactToPrintTrigger}
              content={() => this.componentRef}

              onBeforeGetContent={() => this.getPrintContent()}
              onAfterPrint={() => this.clearAllAfterPrint()}
              removeAfterPrint={true}
          /> 
          </div>
          </div>  
   
 
        
        <Loader
          className='loader'
          type="TailSpin"
          color="#ff9800"
          height={100}
          width={100}
          visible={this.state.loader}
          //3 secs
        />
        <div className="hidden">
          <ComponentToPrint  hClass={this.state.hClass} title={this.state.hTitle} ang={this.state.ang} hukamnama={this.state.hukamnama} ht={this.state.ht} bhogDate={this.state.bhogDate} ref={el => (this.componentRef = el || '')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
      auth: state.firebase.auth,
      userToken: state.auth.action
  }
}
export default connect(mapStateToProps)(Hukamnama)