import React, { Component } from 'react'
import Nav from './nav/nav';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import axios from 'axios';

import './dash.css';

import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getIdToken } from '../store/actions/authActions'

class Dash extends Component {
  

    
        state = {
        }
    



        
    gotoBookings = () => {
        //console.log("Div is clicked")
    }

   
    

    render() {
        if (!this.props.auth.uid) return <Redirect to='/login' />

        return (


            <>
                <Nav />
                <div class="row dashMenu">
                    <div class="col s12 m6 l3 xl2 offset-xl2 offset-l0">

                        <NavLink to='/bookings'>
                            
                                <div className='dashItem'>
                                    <i class="large material-icons dashItemIcon">dashboard</i>
                                    <p className='dashItemTitle'>Bookings Dashboard</p>
                                </div>
                            
                        </NavLink>
                    </div>
                    <div class="col s12  m6 xl2  l3 ">

                        <NavLink to='/membermgmt'>
                                <div className='dashItem'>
                                    <i class="large material-icons dashItemIcon">people</i>
                                    <p className='dashItemTitle'>Membership Management</p>
                                </div>
                            
                        </NavLink>
                    </div>
                    {/* <div class="col s12  m6 xl2  l3 ">

                        <NavLink to='/bookings '>
                                    <div className='dashItem'>
                                        <i class="large material-icons dashItemIcon">assignment_ind</i>
                                    <p className='dashItemTitle'>Bookings Management</p>
                                </div>
                            
                        </NavLink>
                    </div> */}
                    <div class="col s12  m6 xl2  l3 ">
                    <NavLink to='/hukamnama'>
                                <div className='dashItem'>
                                    <i class="large material-icons dashItemIcon">share</i>
                                    <p className='dashItemTitle'>On-Demand Hukamnama</p>
                                </div>
                            
                        </NavLink>

                    </div>
                
                
                </div>
                    
                    
                   
                


                
            </>
        )
    }
}


const mapStateToProps = (state) => {
      return { 
          auth: state.firebase.auth,
          userToken: state.auth.action
      }
    }
export default connect(mapStateToProps)(Dash)
