import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { signOut } from '../../store/actions/authActions';
import { connect } from 'react-redux';

const SignedinLinks = (props) => {
    return (
       <ul className="right">
           <li><NavLink to='/' onClick={props.signOut}>Log Out</NavLink></li>
           <li><NavLink to='/' className='btn btn-floating bgColor'>AS</NavLink></li>
       </ul>
    )
}
const mapDispatchToProps = (dispatch) => {
    ////console.log(dispatch);
    return { 
      signOut: () => dispatch(signOut())
    }
  }
export default connect(null, mapDispatchToProps)(SignedinLinks)