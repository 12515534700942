import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Nav from './nav/nav';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import axios from 'axios';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getIdToken } from '../store/actions/authActions'
import { Row } from 'react-bootstrap';
import './membershipMgmt.css';
import {env} from "../config/env";

class MembershipMgmt extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        //
        this.state = {
            users: null,
            searchTerm: "",
            showModal: false,
            selectedUser: [{"ID":"","firstname":"","surname":"","email":"","address1":"","address2":"","city":"","state":"","country":"","phone":"","date":""}],
            modalTitle: null,
            selectedUID: "",
            editing: false
        }
        
      }

    componentDidMount () {
        const options = {
            onOpenStart: () => {
              ////console.log("Open Start");

              M.updateTextFields();
            },
            onOpenEnd: () => {
    
              M.updateTextFields();
            },
            onCloseStart: () => {
              ////console.log("Close Start");
              M.updateTextFields();
            },
            onCloseEnd: () => {
              ////console.log("Close End");
               //this.setState({modalTitle: "", selectedUser: [{"ID":"","firstname":"","surname":"","email":"","address1":"","address2":"","city":"","state":"","country":"","phone":""}]});
               
               M.updateTextFields();
            },
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: false,
            startingTop: "4%",
            endingTop: "10%"
          };

        const options2 = {
            onOpenStart: () => {
              ////console.log("Open Start");
            },
            onOpenEnd: () => {
              ////console.log("Open End");
            },
            onCloseStart: () => {
              ////console.log("Close Start");
            },
            onCloseEnd: () => {
              ////console.log("Close End");   
            },
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: false,
            startingTop: "4%",
            endingTop: "10%"
          };


          M.Modal.init(this.Modal, options);

          M.Modal.init(this.Modal2, options2);

        ////console.log(this.state.selectedUser);

        
        if(this.props.location.search.substring(1)=='add'){
            M.Modal.getInstance(this.Modal).open();
            
        }
        
    }

    async getAllMembersByx() {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
       axios.get(`${env.backendApiServer}/getMemberByx/${this.state.searchTerm}`, {
            params: {
                idToken,
                uid
            }
        }).then(res => {
            ////console.log(res);
            this.setState({users: res.data});
        })
    }

    async getMemberById(selectedUID) {
        if(selectedUID){
            const uid = this.props.auth.uid;
            const firebase = getFirebase();
            var idToken = null;
            try {
                idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

                ////console.log(idToken);
            } catch(e){
                //console.log(`Error ${e}`);
            }
            //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
        axios.get(`${env.backendApiServer}/getMemberById/${selectedUID}`, {
                params: {
                    idToken,
                    uid
                }
            }).then(res => {
                ////console.log(res.data);
                this.setState({selectedUser: res.data});
                //console.log(this.state.selectedUser);
                
            })
        }
    }

    updateSelectedUser = (event) => {
        ////console.log(event.target.value)
        var obj = this.state.selectedUser;
        const id = event.currentTarget.id;
        obj[0][id] = event.target.value;
        ////console.log(obj[0])
        this.setState({selectedUser: obj});
        //console.log(this.state.selectedUser[0]);
    }

    handleChange = (event) => {
        this.setState({searchTerm: event.target.value});
        this.setState({users: null});
        if(this.state.searchTerm.length >= 3) {
            this.getAllMembersByx()
        }

    }
    memberOperations = async (e) => {
        ////console.log(e.currentTarget.id)
        if(e){
            if(e.currentTarget.id != "new"){
                //this.setState({selectedUID: e.currentTarget.id});
                ////console.log(e.currentTarget.id);
                this.getMemberById(e.currentTarget.id)
                ////console.log(this.state.selectedUser);
                this.setState({modalTitle: "Edit Member Detail"});
                
                ////console.log(this.state.selectedUser[0].ID);
                //this.updateMemberCall();                        
            }else {
                this.setState({modalTitle: "Add New Member"});
                // this.addMemberCall();
                // this.redirect(this.props.location.search.substring(1).split(',')[1]);
            }
        }
    }

    deleteMember = async (e) => {
        
        await this.deleteMemberCall();
        this.getAllMembersByx();
    }

    deleteMemberCall = async () => {
        const selectedUID = this.state.selectedUID;
        if(selectedUID){
            const uid = this.props.auth.uid;
            const firebase = getFirebase();
            var idToken = null;
            try {
                idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);

                ////console.log(idToken);
            } catch(e){
                //console.log(`Error ${e}`);
            }
            //axios.get(`http://127.0.0.1:8080/getBookingsWithMembers`, {
            //console.log(selectedUID);
            axios.delete(`${env.backendApiServer}/deletemember/${selectedUID}?${idToken}&${uid}`
            ).then(res => {
                //console.log(res.data);
                //this.setState({selectedUser: res.data});
                ////console.log(this.state.selectedUser);
            })
        }
    }
    
    updateMember = async () => {
       ////console.log(this.state.selectedUser[0]);

        if(this.state.selectedUser[0].ID){
            //this.setState({modalTitle: "Edit Member Detail"});
            this.updateMemberCall();
            this.getAllMembersByx();          
        }else{
            //this.setState({modalTitle: "Add New Member"});
            this.addMemberCall();
            this.redirect(this.props.location.search.substring(1).split(',')[1]);
        }
       
    }

    redirect = (page) => {
        var ret;
        switch (page) {
            case 'bookings':
                this.props.history.push(`/${page}?useradded`);
                break;
            default:
        }
    }

    updateMemberCall = async () => {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;
        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        //axios.get(`${env.backendApiServer}/getBookingsWithMembers`, {
        //console.log(this.state.selectedUser[0])
        // const payload = {
        //     address1: this.state.selectedUser[0].address1,
        //     address2: this.state.selectedUser[0].address2,
        //     city: this.state.selectedUser[0].city,
        //     country: this.state.selectedUser[0].country,
        //     email: this.state.selectedUser[0].email,
        //     firstname: this.state.selectedUser[0].firstname,
        //     surname: this.state.selectedUser[0].surname,
        //     phone: this.state.selectedUser[0].phone,
        //     state: this.state.selectedUser[0].state,
        //     date: this.state.selectedUser[0].date
        // }
       axios.patch(`${env.backendApiServer}/updateMember/${this.state.selectedUser[0].ID}?idToken=${idToken}&uid=${uid}`, this.state.selectedUser[0]).then(res => {
            //console.log(res);
            //this.setState({selectedUser: res.data});
            ////console.log(this.state.selectedUser);
            this.setState({modalTitle: "", selectedUser: [{"ID":"","firstname":"","surname":"","email":"","address1":"","address2":"","city":"","state":"","country":"","phone":""}]});
        })
    }

    addMemberCall = async () => {
        const uid = this.props.auth.uid;
        const firebase = getFirebase();
        var idToken = null;

        try {
            idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
            ////console.log(idToken);
        } catch(e){
            //console.log(`Error ${e}`);
        }
        

        // const payload = {
        //     address1: this.state.selectedUser[0].address1,
        //     address2: this.state.selectedUser[0].address2,
        //     city: this.state.selectedUser[0].city,
        //     country: this.state.selectedUser[0].country,
        //     email: this.state.selectedUser[0].email,
        //     firstname: this.state.selectedUser[0].firstname,
        //     surname: this.state.selectedUser[0].surname,
        //     phone: this.state.selectedUser[0].phone,
        //     state: this.state.selectedUser[0].state,
        //     date: new Date().getFullYear()+"-"+(parseInt(new Date().getMonth())+1)+"-"+new Date().getDate()
        // }
      const formattedDate = new Date().getFullYear()+"-"+(parseInt(new Date().getMonth())+1)+"-"+new Date().getDate();
      ////console.log(formattedDate)
      var obj = this.state.selectedUser;
      obj[0]['date'] = formattedDate;
      await this.setState({selectedUser: obj});

      //console.log(this.state.selectedUser[0]);
        //axios.get(`${env.backendApiServer}/getBookingsWithMembers`, {

       axios.post(`${env.backendApiServer}/addmember?idToken=${idToken}&uid=${uid}`, this.state.selectedUser[0]).then(res => {
            ////console.log(res);
            //this.getAllBookingsByx();
            //this.setState({selectedUser: res.data});
            //this.state.selectedUser);
            if(this.props.location.search.substring(1)=='add'){
                return window.location.href=`/bookings?add=${this.state.selectedUser[0].firstname}`
            }
            this.setState({modalTitle: "", selectedUser: [{"ID":"","firstname":"","surname":"","email":"","address1":"","address2":"","city":"","state":"","country":"","phone":""}]});
        })


    }
    render() {
        return (
            <div>
                <Nav />
                <NavLink to='/'><i className="small material-icons backArrow">keyboard_return</i></NavLink>
                <div className="modalButtonMargin">
                        <a className="modal-trigger waves-effect waves-light btn" data-target="modal1"  id="new" onClick={this.memberOperations}>
                            Add Member
                        </a>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="searchTerm" type="text" className="validate" onChange={this.handleChange} />
                        <label className="active" htmlFor="searchTerm">Search Members</label>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table className="table" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Address</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Phone</TableCell>
                            <TableCell align="right">Actions</TableCell>
                            {/* <TableCell align="right">Hukamnama Ang</TableCell>
                            <TableCell align="right">Hukamnama Sabad</TableCell> */}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.users ? this.state.users.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.firstname+" "+row.surname}
                            </TableCell>
                            <TableCell align="right">{row.address1 ? row.address1+' ,': ""} {row.address2 ? row.address2+' ,': ""} {row.city ? row.city +' ,': ""} {row.state?row.state+' ,': ""} {row.country ? row.country: ""}</TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                            <TableCell align="right">{row.phone}</TableCell>
                    
                            <TableCell align="right">
                                <i className="small material-icons userActionButton modal-trigger" func="edit" id={row.ID} data-target="modal1" onClick={this.memberOperations}>edit</i>
                                <i className="small material-icons userActionButton modal-trigger redHover" data-target="modal2" id={row.ID} onClick={(e)=>this.setState({selectedUID: e.currentTarget.id})}>delete_forever</i>
                            </TableCell>
                            {/* <TableCell align="right">{row.hukamnamaang}</TableCell>
                            <TableCell align="right">{row.hukamnamsabad}</TableCell> */}
                            </TableRow>
                        )): <TableRow></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                
                
                {/* Start of Modal */}
                <div
                ref={Modal => {
                    this.Modal = Modal;
                }}
                id="modal1"
                className="modal modal-fixed-footer"
                >
                {/* If you want Bottom Sheet Modal then add 
                                bottom-sheet class to the "modal" div
                                If you want Fixed Footer Modal then add
                                modal-fixed-footer to the "modal" div*/}
                <div className="modal-content">
                    <h4>{this.state.modalTitle}</h4>
                            { 1 ?  
                                <form className="col s12">
                                        <div className="input-field col s6 ">
                                            <i className="material-icons prefix">featured_play_list</i>
                                            <input disabled onChange={this.updateSelectedUser} value={this.state.selectedUser[0].ID} id="ID" type="text" className="validate"/>
                                            <label htmlFor="id">ID</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">person</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].firstname} id="firstname" type="text" className="validate"/>
                                            <label htmlFor="firstname">First Name</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">person</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].surname} id="surname" type="text" className="validate"/>
                                            <label htmlFor="surname">Last Name</label>
                                        </div>
                                
                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">home</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].address1} id="address1" type="text" className="validate"/>
                                            <label htmlFor="address1">Address 1</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">home</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].address2} id="address2" type="text" className="validate"/>
                                            <label htmlFor="address2">Address 2</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">location_city</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].city} id="city" type="text" className="validate"/>
                                            <label htmlFor="city">City</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">map</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].state} id="state" type="text" className="validate"/>
                                            <label htmlFor="state">State</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">map</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].country} id="country" type="text" className="validate"/>
                                            <label htmlFor="country">Country</label>
                                        </div>

                             
                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">email</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].email} id="email" type="text" className="validate"/>
                                            <label htmlFor="email">Email</label>
                                        </div>

                                        <div className="input-field col s6">
                                            <i className="material-icons prefix">phone</i>
                                            <input onChange={this.updateSelectedUser}  value={this.state.selectedUser[0].phone} id="phone" type="text" className="validate"/>
                                            <label htmlFor="phone">Phone</label>
                                        </div>
                                </form>  
                            : <div>Loading...</div>}    
                    </div>
                <div className="modal-footer">
                    <div className="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" onClick={this.updateMember}>
                        OK
                        </a>
                    </div>
                    <div className="modalButtonMargin">
                        <a className="modal-close waves-effect waves-light btn" >
                        Cancel
                        
                        </a>
                    </div>
                    
                </div>
                </div>

                <div
                    ref={Modal2 => {
                        this.Modal2 = Modal2;
                    }}
                    id="modal2"
                    className="modal"
                >
                    <div className="modal-content">
                    <h4>Delete Confirmation</h4>
                    <p >Are you sure you want to delete user ID # {this.state.selectedUID}</p>
                    <blockquote className='text-orange text-darken-3'>This step is irreversible.</blockquote>
                    
                    </div>
                    <div className="modal-footer">
                        <div className="modalButtonMargin">
                            <a href="#!" className="modal-close waves-effect waves-light btn">Cancel</a>
                        </div>
                        <div className="modalButtonMargin">
                            <a href="#!" className="modal-close waves-effect waves-light btn btn-red" onClick={this.deleteMember} >Delete</a>
                        </div>
                        
                    </div>
                </div>
            </div>




        )
    }
}
const mapStateToProps = (state) => {
    return { 
        auth: state.firebase.auth,
        userToken: state.auth.action
    }
  }
export default connect(mapStateToProps)(MembershipMgmt)